import * as React from "react";
import styled from "styled-components-themed";
import { getShare } from "api/share";
import { Box, Heading } from "grommet";
import { useFetch, Spinning, NotFound } from "shared-ui";
import { displayUSD } from "@rr/utils";
import Group from "../../../../calculator/src/pages/Results/Group";
import UnquantifiableValue from "../../../../calculator/src/pages/Results/UnquantifiableValue";
import { calculateGroupTotals } from "@rr/utils/calculator/totals";
import {
  organizationalValue,
  organizationalInvestment,
} from "@rr/utils/calculator/groups";
import SEO from "../seo";

interface Props {
  id: string;
}

const Wrapper = styled.div`
  margin-top: 3rem;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  color: #333;
`;

const Container = styled.div`
  padding: 1rem 0;
  margin: 0 auto;
  width: 100%;
  max-width: 64rem;
`;

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem 0;
  margin-bottom: 2.5rem;
  border-bottom: 0.125rem solid #ebebeb;
`;

const ShareNetValue: React.FunctionComponent<Props> = (props) => {
  const { id } = props;
  const { isLoading, data } = useFetch(getShare, id);

  if (isLoading) {
    return <Spinning />;
  }

  if (!data) {
    return <NotFound />;
  }

  const {
    monetaryValueOfConsultants,
    monetaryValueOfProducts,
    longTermBusinessValue,
    longTermBusinessValueUniqueToProBono,
    staffTime,
    opportunityCostOfImplementation,
    implementationAndMaintenance,
    unquantifiableValue,
  } = data;

  return (
    <Wrapper>
      <SEO title="Net Value" />
      <Container>
        <Heading color="#2d4a58" margin={{ bottom: "1.5rem", top: "1rem" }}>
          Estimate Net Value
        </Heading>
        <HeaderWrapper>
          <Heading level={3} size="large" color="#2d4a58">
            Net Value
          </Heading>
          <Heading level={3} size="large">
            {displayUSD(
              calculateGroupTotals(organizationalValue, data) -
                calculateGroupTotals(organizationalInvestment, data),
            )}
          </Heading>
        </HeaderWrapper>
        <UnquantifiableValue items={unquantifiableValue} />
        <Box>
          <HeaderWrapper>
            <Heading level={3} size="medium" color="#2d4a58">
              Organizational Value
            </Heading>
            <Heading level={3} size="medium">
              {displayUSD(calculateGroupTotals(organizationalValue, data))}
            </Heading>
          </HeaderWrapper>
          <Group
            header="Monetary Value of Volunteers"
            items={monetaryValueOfConsultants!}
          />
          <Group
            header="Monetary Value of Products"
            items={monetaryValueOfProducts!}
          />
          <Group
            header="Long Term Business Value"
            items={longTermBusinessValue!}
          />
          <Group
            header="Long Term Business Value Unique to Pro Bono"
            items={longTermBusinessValueUniqueToProBono!}
          />
        </Box>
        <Box>
          <HeaderWrapper>
            <Heading level={3} size="medium" color="#2d4a58">
              Organizational Investment
            </Heading>
            <Heading level={3} size="medium">
              -{" "}
              {displayUSD(calculateGroupTotals(organizationalInvestment, data))}
            </Heading>
          </HeaderWrapper>
          <Group header="Staff Time" sign="-" items={staffTime!} />
          <Group
            header="Opportunity Cost of Implementation"
            sign="-"
            items={opportunityCostOfImplementation!}
          />
          <Group
            header="Implementation and Maintenance"
            sign="-"
            items={implementationAndMaintenance!}
          />
        </Box>
      </Container>
    </Wrapper>
  );
};

export default ShareNetValue;
