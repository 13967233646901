import * as React from "react";
import styled from "styled-components-themed";
import format from "date-fns/format";
import { getShare } from "api/share";
import { Box, Heading as HeadingBase } from "grommet";
import { useFetch, Container, Spinning, NotFound, media } from "shared-ui";
import SEO from "../seo";

interface Props {
  id: string;
}

const Wrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 4rem;
  align-items: flex-start;

  ${media.large`
    display: flex;
  `}
`;

const Sidebar = styled.aside`
  border: 0.125rem solid #d8d8d8;
  border-radius: 0.375rem;
  padding: 1rem;
  min-width: 21.875rem;
  flex-basis: 21.875rem;

  ${media.large`
    margin-left: 4rem;
  `}

  > div {
    background: #f7f9f8;
    margin-bottom: 2rem;
  }
`;

const Main = styled.main`
  width: 100%;
`;

const SubTitle = styled.p`
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const ProjectTitle = styled(HeadingBase)`
  margin-top: 1rem;
  margin-bottom: 3rem;
`;

export const Heading = styled(HeadingBase)`
  max-width: none;
  border-bottom: 1px solid #b6ccc2;
`;

const SubHeading = styled(HeadingBase)`
  max-width: none;
  padding-bottom: 0.25rem;
  border-bottom: 1px solid #b6ccc2;
  font-size: 1.3125rem;
  font-family: ${(props) => props.theme.fonts.base};
`;

const PhaseHeader = styled.div`
  position: relative;
  margin-top: 2.5rem;
`;

export const P = styled.p`
  margin-bottom: 1rem;

  em {
    font-style: normal;
    text-decoration: underline;
  }
`;

const ShareScope: React.FunctionComponent<Props> = (props) => {
  const { id } = props;
  const { isLoading, data } = useFetch(getShare, id);

  if (isLoading) {
    return <Spinning />;
  }

  if (!data) {
    return <NotFound />;
  }

  return (
    <Container>
      <SEO title={data.name} />

      <Wrapper>
        <Main>
          <SubTitle>Skills based volunteering opportunity</SubTitle>
          <ProjectTitle level={1}>{data.name}</ProjectTitle>

          <Heading level={3}>Project Need</Heading>
          <Box margin={{ top: "medium", bottom: "large" }}>
            {data.projectNeed
              .map((item: any) =>
                item.textarea
                  ? item.textarea.leadingText + item.answer
                  : item.answer,
              )
              .join(" ")}
          </Box>

          <Heading level={3}>Project Description</Heading>
          <Box margin={{ top: "medium", bottom: "large" }}>
            {data.projectDescription
              .map((item: any) =>
                item.textarea
                  ? item.textarea.leadingText + item.answer
                  : item.answer,
              )
              .join(" ")}
          </Box>

          <Heading level={3}>Project Phases</Heading>
          <Box margin={{ bottom: "large" }}>
            {data.projectPhases.map((phase: any, phaseIndex: number) => (
              <Box key={phaseIndex} margin={{ left: "2rem" }}>
                <PhaseHeader>
                  <SubHeading level={4}>
                    Phase {phaseIndex + 1}{" "}
                    {phase[0].answer ? `- ${phase[0].answer}` : ""}
                  </SubHeading>
                </PhaseHeader>
                <Box margin={{ top: "small" }}>
                  <P>{phase[1].answer}</P>
                  <P>
                    <em>Milestone:</em> {phase[2].answer}
                  </P>
                </Box>
              </Box>
            ))}
          </Box>

          <Heading level={3}>Skills Needed</Heading>
          <Box margin={{ top: "medium", bottom: "large" }}>
            {data.skillsNeeded.selectedAreas.length > 0 && (
              <P>
                <em>Areas of Need:</em>{" "}
                {data.skillsNeeded.selectedAreas.join(", ")}
              </P>
            )}
            {Object.keys(data.skillsNeeded.selectedHardSkills).length > 0 && (
              <P>
                <em>Functional Skills:</em>{" "}
                {Object.values<any>(data.skillsNeeded.selectedHardSkills)
                  .reduce((acc, cur) => [...acc, ...cur], [])
                  .join(", ")}
              </P>
            )}
            {data.skillsNeeded.selectedSoftSkills.length > 0 && (
              <P>
                <em>Cross-Functional Skills:</em>{" "}
                {data.skillsNeeded.selectedSoftSkills.join(", ")}
              </P>
            )}
            {data.skillsNeeded.otherSkills && (
              <P>
                <em>Other skills:</em> {data.skillsNeeded.otherSkills}
              </P>
            )}
          </Box>

          <Heading level={3}>Project Timeline</Heading>
          <Box margin={{ top: "medium", bottom: "large" }}>
            <P>
              {data.projectDuration.enabled &&
              data.projectDuration.startDate &&
              data.projectDuration.endDate
                ? `${format(
                    data.projectDuration.startDate,
                    "MMMM DD, YYYY",
                  )} to ${format(
                    data.projectDuration.endDate,
                    "MMMM DD, YYYY",
                  )}`
                : "No estimated project duration."}
            </P>
          </Box>
        </Main>

        {data.organization.name && data.organization.contact && (
          <Sidebar>
            <Heading level={3} margin={{ top: "small", bottom: "medium" }}>
              {data.organization.name}
            </Heading>
            <P>Contact:</P>
            <p>
              <strong>{data.organization.contact.name}</strong>
            </p>
            <p>{data.organization.contact.title}</p>
            <p>{data.organization.contact.phoneNumber}</p>
            <p>
              <a
                href={`mailto:${data.organization.contact.email}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Email
              </a>
            </p>
          </Sidebar>
        )}
      </Wrapper>
    </Container>
  );
};

export default ShareScope;
