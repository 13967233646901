import * as React from "react";
import { Router } from "@reach/router";
import { Route } from "shared-ui";
import SEO from "../components/seo";
import ShareScope from "../components/share/Scope";
import ShareNetValue from "../components/share/NetValue";

const SharePage = (): JSX.Element => {
  return (
    <React.Fragment>
      <SEO title="Share" />
      <Router basepath="share">
        <Route component={ShareScope} path="scope/:id" />
        <Route component={ShareNetValue} path="net-value/:id" />
        {/* <Route default component={NotFound} /> */}
      </Router>
    </React.Fragment>
  );
};

export default SharePage;
